import React from 'react';
import iccream from '../../img/icream-min.jpg'
import cake from '../../img/cake.jpg'
import juice from '../../img/orangeJuice.jpg'
const Banner = () => {
  return (
      <>
          <div id="banner">
          <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src={iccream} className="img-fluid" alt="..." />
    </div>
    <div className="carousel-item">
      <img src={cake} className="img-fluid" alt="..." />
    </div>
    <div className="carousel-item">
      <img src={juice} className="img-fluid" alt="..." />
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true" />
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true" />
    <span className="visually-hidden">Next</span>
  </button>
</div>

          </div>

      </>
  );
};

export default Banner;
