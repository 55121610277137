import React from "react";

const List = () => {
  return (
    <div class="list">
      <div className="container listInfo">
        <h1 id="listHeader">Dairy & Ice Cream Industries</h1>
        <p id="listPara">
          The carousel is a slideshow for cycling through a series of content,
          built with CSS 3D transforms and a bit of JavaScript. It works with a
          series of images, text, or custom markup. It also includes support for
          previous/next controls and indicators.The carousel is a slideshow for cycling through a series of content,
          built with CSS 3D transforms and a bit of JavaScript
        </p>
      </div>

      <div className="listProduct d-flex justify-content-start align-items-center bd-highlight container">
        <div>
          <ul>
            <li>Cold Drinks</li>
            <li>Hot Drinks</li>
            <li>Ice-Creams</li>
            <li>Coca-Cola</li>
            <li>Fanta</li>
            <li>Ice Tea</li>
            <li>Coca-Cola</li>
            <li>Fanta</li>
            <li>Ice Tea</li>
            <li>Cold Drinks</li>
            <li>Hot Drinks</li>
            <li>Ice-Creams</li>
            <li>Coca-Cola</li>
            <li>Ice Tea</li>
            
          </ul>
        </div>
        <div>
          <ul>
          <li>Cold Drinks</li>
            <li>Hot Drinks</li>
            <li>Ice-Creams</li>
            <li>Coca-Cola</li>
            <li>Fanta</li>
            <li>Ice Tea</li>
            <li>Coca-Cola</li>
            <li>Fanta</li>
            <li>Ice Tea</li>
            <li>Cold Drinks</li>
            <li>Hot Drinks</li>
            <li>Ice-Creams</li>
            <li>Coca-Cola</li>
            <li>Ice Tea</li>
            
          </ul>
        </div>
        <div>
          <ul>
          <li>Cold Drinks</li>
            <li>Hot Drinks</li>
            <li>Ice-Creams</li>
            <li>Coca-Cola</li>
            <li>Fanta</li>
            <li>Ice Tea</li>
            <li>Coca-Cola</li>
            <li>Fanta</li>
            <li>Ice Tea</li>
            <li>Cold Drinks</li>
            <li>Hot Drinks</li>
            <li>Ice-Creams</li>
            <li>Coca-Cola</li>
            <li>Ice Tea</li>
            
          </ul>
        </div>
        
        <div className="listImgMain">
        
          <img
            src="https://images.pexels.com/photos/1508666/pexels-photo-1508666.jpeg?cs=srgb&dl=pexels-daria-shevtsova-1508666.jpg&fm=jpg"
            alt=""
            className="img-fluid ListImg"
          />
        </div>
      </div>
    </div>
  );
};

export default List;
