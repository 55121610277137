import React from 'react';
import Banner from './component/Banner/Banner';
import Allproduct from './component/allProduct/Allproduct';
import Navbar from './component/Navbar/Navbar';
import Product from './component/Product/Product';
import Demo from './component/demo/Demo';
import List from './Pages/Products/List';

function App() {
  return (
    <div >
      <Navbar />
      <Banner />
      <Product />
      {/* <Allproduct /> */}
      <Demo />
      <List />
    </div>
  );
}

export default App;
