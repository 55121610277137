import React from "react";
import Carousel from "react-elastic-carousel";
import Item from "./Item";
import "./styles.css";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 4 },
  { width: 1200, itemsToShow: 4 },
];

const Demo = () => {
  return (
   <>
      <div className="container">
      <div className="App">
        <Carousel breakPoints={breakPoints}>
          <Item>
            <div className="member m-1">
            <div className="member-info text-center border-dark">
                <h4 className="memberh4">Walter White</h4>
                
              </div>
              <div className="member-img">
                <img
                  src="https://images.pexels.com/photos/4686940/pexels-photo-4686940.jpeg?cs=srgb&dl=pexels-cottonbro-4686940.jpg&fm=jpg"
                  className="img-fluid memberImg"
                  alt
                />
                <div className="social">
                  <a href>
                    <i className="bi bi-twitter" />
                  </a>
                  <a href>
                    <i className="bi bi-facebook" />
                  </a>
                  <a href>
                    <i className="bi bi-instagram" />
                  </a>
                  <a href>
                    <i className="bi bi-linkedin" />
                  </a>
                </div>
              </div>
              
            </div>
          </Item>
          <Item>
          <div className="member m-1">
            <div className="member-info text-center border-dark">
                <h4 className="memberh4">Walter White</h4>
                
              </div>
              <div className="member-img">
                <img
                  src="https://images.pexels.com/photos/890500/pexels-photo-890500.jpeg?cs=srgb&dl=pexels-oleg-magni-890500.jpg&fm=jpg"
                  className="img-fluid memberImg"
                  alt
                />
                <div className="social">
                  <a href>
                    <i className="bi bi-twitter" />
                  </a>
                  <a href>
                    <i className="bi bi-facebook" />
                  </a>
                  <a href>
                    <i className="bi bi-instagram" />
                  </a>
                  <a href>
                    <i className="bi bi-linkedin" />
                  </a>
                </div>
              </div>
              
            </div>
          </Item>
          <Item>
          <div className="member m-1">
            <div className="member-info text-center border-dark">
                <h4 className="memberh4">Walter White</h4>
                
              </div>
              <div className="member-img">
                <img
                  src="https://images.pexels.com/photos/1309583/pexels-photo-1309583.jpeg?cs=srgb&dl=pexels-kumaran-1309583.jpg&fm=jpg"
                  className="img-fluid memberImg"
                  alt
                />
                <div className="social">
                  <a href>
                    <i className="bi bi-twitter" />
                  </a>
                  <a href>
                    <i className="bi bi-facebook" />
                  </a>
                  <a href>
                    <i className="bi bi-instagram" />
                  </a>
                  <a href>
                    <i className="bi bi-linkedin" />
                  </a>
                </div>
              </div>
              
            </div>
          </Item>
          <Item>
          <div className="member m-1">
            <div className="member-info text-center border-dark">
                <h4 className="memberh4">Walter White</h4>
                
              </div>
              <div className="member-img">
                <img
                  src="https://images.pexels.com/photos/4110008/pexels-photo-4110008.jpeg?cs=srgb&dl=pexels-polina-tankilevitch-4110008.jpg&fm=jpg"
                  className="img-fluid memberImg"
                  alt
                />
                <div className="social">
                  <a href>
                    <i className="bi bi-twitter" />
                  </a>
                  <a href>
                    <i className="bi bi-facebook" />
                  </a>
                  <a href>
                    <i className="bi bi-instagram" />
                  </a>
                  <a href>
                    <i className="bi bi-linkedin" />
                  </a>
                </div>
              </div>
              
            </div>
          </Item>
          <Item>
          <div className="member m-1">
            <div className="member-info text-center border-dark">
                <h4 className="memberh4">Walter White</h4>
                
              </div>
              <div className="member-img">
                <img
                  src="https://images.pexels.com/photos/2672942/pexels-photo-2672942.jpeg?cs=srgb&dl=pexels-rania-alhamed-2672942.jpg&fm=jpg"
                  className="img-fluid memberImg"
                  alt
                />
                <div className="social">
                  <a href>
                    <i className="bi bi-twitter" />
                  </a>
                  <a href>
                    <i className="bi bi-facebook" />
                  </a>
                  <a href>
                    <i className="bi bi-instagram" />
                  </a>
                  <a href>
                    <i className="bi bi-linkedin" />
                  </a>
                </div>
              </div>
              
            </div>
          </Item>
          <Item>
            <div className="member m-1">
            <div className="member-info text-center border-dark">
                <h4 className="memberh4">Walter White</h4>
                
              </div>
              <div className="member-img">
                <img
                  src="https://images.pexels.com/photos/3938932/pexels-photo-3938932.jpeg?cs=srgb&dl=pexels-itschansy-3938932.jpg&fm=jpg"
                  className="img-fluid memberImg"
                  alt
                />
                <div className="social">
                  <a href>
                    <i className="bi bi-twitter" />
                  </a>
                  <a href>
                    <i className="bi bi-facebook" />
                  </a>
                  <a href>
                    <i className="bi bi-instagram" />
                  </a>
                  <a href>
                    <i className="bi bi-linkedin" />
                  </a>
                </div>
              </div>
              
            </div>
          </Item>
          <Item>
            <div className="member m-1">
            <div className="member-info text-center border-dark">
                <h4 className="memberh4">Walter White</h4>
                
              </div>
              <div className="member-img">
                <img
                  src="https://images.pexels.com/photos/2615323/pexels-photo-2615323.jpeg?cs=srgb&dl=pexels-ekrulila-2615323.jpg&fm=jpg"
                  className="img-fluid memberImg"
                  alt
                />
                <div className="social">
                  <a href>
                    <i className="bi bi-twitter" />
                  </a>
                  <a href>
                    <i className="bi bi-facebook" />
                  </a>
                  <a href>
                    <i className="bi bi-instagram" />
                  </a>
                  <a href>
                    <i className="bi bi-linkedin" />
                  </a>
                </div>
              </div>
              
            </div>
          </Item>
        </Carousel>
      </div>
      
    </div>
    
   </>
  );
};

export default Demo;
