import React from 'react';
import logo from '../../img/logo.jfif'
const Navbar = () => {
  return (
    <div>
  <header id="header" className="fixed-top">
    <div className="container d-flex align-items-center justify-content-between">
     
      {/* Uncomment below if you prefer to use an image logo */}
      <a href="index.html" class="logo">
      <img src={logo} alt=""  width="160" height="300"/>
      </a>
      <nav id="navbar" className="navbar">
        <ul>
          <li><a className="nav-link scrollto active me-2" href="#hero">Home</a></li>
          <li><a className="nav-link scrollto me-2" href="#about">Flavours</a></li>
          <li><a className="nav-link scrollto me-2" href="#services">Products</a></li>
          <li><a className="nav-link scrollto me-2 o" href="#portfolio">Blogs</a></li>
          <li><a className="nav-link scrollto me-2" href="#team">About Us</a></li>
          
          
          <li><a className="nav-link scrollto" href="#contact">Contact Us</a></li>
          
        </ul>
        <i className="bi bi-list mobile-nav-toggle" />
      </nav>{/* .navbar */}
    </div>
  </header>
</div>



  )
  
};

export default Navbar;
