import React from "react";

const Product = () => {
  return (
    <div>
      <section id="product" class="d-flex align-items-center">
        <div
          class="container position-relative"
          data-aos="fade-up"
          data-aos-delay="100"
        >
        <div class="prodHeader"><h2 class="border-bottom prodh" id="">Product Application</h2></div>
          <div class="row icon-boxes">
            <div
              class="col-md-6 col-lg-3  mb-5 mb-lg-0"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div class="icon-box">
                <div class="circle"><div><img src="https://images.pexels.com/photos/128242/pexels-photo-128242.jpeg?cs=srgb&dl=pexels-markus-spiske-128242.jpg&fm=jpg"  class="circle img-fluid"/></div></div>
                <h4 class="title text-center mt-5">
                  <a href="">Creamy Indulgence</a>
                </h4>
              </div>
            </div>

            <div
              class="col-md-6 col-lg-3  mb-5 mb-lg-0"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div class="icon-box">
                <div class="circle"><div><img src="https://images.pexels.com/photos/4085480/pexels-photo-4085480.jpeg?cs=srgb&dl=pexels-anna-pyshniuk-4085480.jpg&fm=jpg"  class="circle img-fluid"/></div></div>
                <h4 class="title text-center mt-5">
                  <a href="">Pure Refreshment</a>
                </h4>
              </div>
            </div>

            <div
              class="col-md-6 col-lg-3  mb-5 mb-lg-0"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div class="icon-box">
                <div class="circle"><div><img src="https://images.pexels.com/photos/8375246/pexels-photo-8375246.jpeg?cs=srgb&dl=pexels-denys-gromov-8375246.jpg&fm=jpg"  class="circle img-fluid"/></div></div>
                <h4 class="title text-center mt-5">
                  <a href="">Mouth-Freshning Sensation</a>
                </h4>
              </div>
            </div>

            <div
              class="col-md-6 col-lg-3  mb-5 mb-lg-0"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div class="icon-box">
                <div class="circle"><div><img src="https://images.pexels.com/photos/2672942/pexels-photo-2672942.jpeg?cs=srgb&dl=pexels-rania-alhamed-2672942.jpg&fm=jpg"  class="circle img-fluid"/></div></div>
                <h4 class="title text-center mt-5">
                  <a href="">Pioneering extraordinary taste</a>
                </h4>
              </div>
            </div>

          </div>

          {/* row 2 */}
          <div class="row icon-boxes">
            <div
              class="col-md-6 col-lg-3  mb-5 mb-lg-0"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div class="icon-box">
                <div class="circle"><div><img src="https://images.pexels.com/photos/7793766/pexels-photo-7793766.jpeg?cs=srgb&dl=pexels-andrew-neel-7793766.jpg&fm=jpg"  class="circle img-fluid"/></div></div>
                <h4 class="title text-center mt-5">
                  <a href="">The Sweet Fruit Temptation</a>
                </h4>
              </div>
            </div>

            <div
              class="col-md-6 col-lg-3  mb-5 mb-lg-0"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div class="icon-box">
                <div class="circle"><div><img src="https://images.pexels.com/photos/8755972/pexels-photo-8755972.jpeg?cs=srgb&dl=pexels-k%C3%BCbra-do%C4%9Fu-8755972.jpg&fm=jpg"  class="circle img-fluid"/></div></div>
                <h4 class="title text-center mt-5">
                  <a href="">Scrumpttiously Delightful</a>
                </h4>
              </div>
            </div>

            <div
              class="col-md-6 col-lg-3  mb-5 mb-lg-0"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div class="icon-box">
                <div class="circle"><div><img src="https://images.pexels.com/photos/2082617/pexels-photo-2082617.jpeg?cs=srgb&dl=pexels-myburgh-roux-2082617.jpg&fm=jpg"  class="circle img-fluid"/></div></div>
                <h4 class="title text-center mt-5">
                  <a href="">A coat of flavourable health</a>
                </h4>
              </div>
            </div>

            <div
              class="col-md-6 col-lg-3  mb-5 mb-lg-0"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div class="icon-box">
                <div class="circle"><div><img src="https://images.pexels.com/photos/1414234/pexels-photo-1414234.jpeg?cs=srgb&dl=pexels-eric-montanah-1414234.jpg&fm=jpg"  class="circle img-fluid"/></div></div>
                <h5 class="title text-center mt-5">
                  <a href="">Sourced from creation itself</a>
                </h5>
              </div>
            </div>
            
          </div>
        </div>
      </section>
    </div>
  );
};

export default Product;
